import React, {useEffect, useState, Fragment, useContext} from 'react'
import { loadModules, setDefaultOptions } from 'esri-loader'
import LoadingBox from '../design/loadingbox'
import { useCookies } from 'react-cookie'
import { hideOnClickOutside } from '../../utils/esri-helpers.js'
import { generateFilter, decodeCookie, boundaryLayers, getTrueLayerName, loginFlow } from '../../utils/constants.js'
import { LoaderContext } from '../../contexts/loader'
import { ValidateContext } from '../../contexts/validate'
import { navigate } from 'gatsby'

setDefaultOptions({ version: '4.27' })

/* Making a separate wrapping element for the whole app that mostly serves to wait until the map-related modules load from Esri and then uses them */

// Get the URLs depending on our environment
let redirectUri = 'http://localhost:8888/oauth-callback'
const currentEnv = process.env.GATSBY_ENV
if (currentEnv === "prod"){
  redirectUri = 'https://www.paverops.com/oauth-callback'
}
if (currentEnv === "stage"){
  redirectUri = 'https://stage--paverops.netlify.app/oauth-callback'
}

const MapLoader = ({children, lambdaUri}) => {
  let [modules, setModules] = useState({})
  let [error, setError] = useState("")
  const [cookies, setCookie, removeCookie] = useCookies(['paverReg'])
  const validateContext = useContext(ValidateContext)

  // Safety check so we don't crash the app
  if (!cookies.paverReg){
    cookies.paverReg = {}
  } else {
    // Decode the result
    //console.log("cookies.paverReg", cookies.paverReg)
    cookies.paverReg = decodeCookie(cookies.paverReg)
  }
  // Create a feature layer we can edit on
  const editLayers = {}

  let createFeatureLayers = (urlList, FeatureLayer) => {
    const promiseStack = []
    // Now do the requests
    for (let url in urlList){
      let featureQuery = urlList[url]
      // Set up a promise that will let us wait for resolution
      let promiseItem = new Promise((resolve,reject) => { // TODO: Consider removing this promise since we don't need it anymore, will need to reformat things a bit though

        // For certain layers, we only want to display a subset
        let extraFilter = generateFilter(featureQuery)
        // if (extraFilter){
        //   extraFilter = " AND " + extraFilter
        // }

        let layerOptions = {
          url: featureQuery,
          // REMOVING MOST DEF EXPRESSIONS, VIEWS ARE HANDLING EVERYTHING
          //definitionExpression: `MemberId <> NULL${extraFilter}`
          // NOTE: If we need to put MemberId <> NULL back, remember to add the AND back in above
          definitionExpression: `${extraFilter}`
        }

        //For project, we'll have a special line renderer to show thick lines close up
        if (featureQuery.indexOf("%28Project_Test") !== -1 || featureQuery.indexOf("%28Project") !== -1){
          // If complete, style: "short-dot"
          // If future, style: "short-dash"
          let lineStyle = "solid"
          if (featureQuery.indexOf("Complete") !== -1){
            lineStyle = "short-dot"
          }
          if (featureQuery.indexOf("Future") !== -1){
            lineStyle = "short-dash"
          }
          if (featureQuery.indexOf("Under_Construction") !== -1){
            lineStyle = "dash-dot"
          }
          const memberProjectRenderer = {
            "type": "unique-value",
            field: "ProjType",
            defaultSymbol: { 
              type: "simple-line", 
              color: {r: 110, g: 52, b: 127, a: 0.7},
              style: lineStyle,
              width: 3
            },
            "uniqueValueInfos": [
              {
                value: "Gas",
                symbol: {
                  type: "simple-line",
                  color: {r: 240, g: 240, b: 0, a: 0.7},
                  style: lineStyle,
                  width: 3
                }
              },
              {
                value: "Elec",
                symbol: {
                  type: "simple-line",
                  color: {r: 217, g: 0, b: 0, a: 0.7},
                  style: lineStyle,
                  width: 3
                }
              },
              {
                value: "Storm",
                symbol: {
                  type: "simple-line",
                  color: {r: 0, g: 148, b: 116, a: 0.7},
                  style: lineStyle,
                  width: 3
                }
              },
              {
                value: "Wastewater",
                symbol: {
                  type: "simple-line",
                  color: {r: 54, g: 163, b: 0, a: 0.7},
                  style: lineStyle,
                  width: 3
                }
              },
              {
                value: "Comm",
                symbol: {
                  type: "simple-line",
                  color: {r: 217, g: 145, b: 0, a: 0.7},
                  style: lineStyle,
                  width: 3
                }
              },
              {
                value: "ReW",
                symbol: {
                  type: "simple-line",
                  color: {r: 167, g: 0, b: 217, a: 0.7},
                  style: lineStyle,
                  width: 3
                }
              },
              {
                value: "Water",
                symbol: {
                  type: "simple-line",
                  color: {r: 0, g: 112, b: 255, a: 0.7},
                  style: lineStyle,
                  width: 3
                }
              },
              {
                value: "HazMat",
                symbol: {
                  type: "simple-line",
                  color: {r: 140, g: 140, b: 0, a: 0.7},
                  style: lineStyle,
                  width: 3
                }
              },
              {
                value: "Trans",
                symbol: {
                  type: "simple-line",
                  color: {r: 225, g: 210, b: 209, a: 0.7},
                  style: lineStyle,
                  width: 3
                }
              }
            ]
          }

          layerOptions.renderer = memberProjectRenderer
          layerOptions.effect = "blur(2px) saturate(200%)"
        }

        //For alerts, there's another custom treatment
        if (featureQuery.indexOf("%28Alert") !== -1){
          const alertRenderer = {
            "type": "unique-value",
            field: "AlertStatus",
            defaultLabel: "Active",
            defaultSymbol: { 
              type: "simple-line", 
              color: {r: 216, g: 110, b: 110, a: 0.7},
              style: "solid",
              width: 4.3
            },
            uniqueValueInfos: [{
              value: "Suitable",
              symbol: {
                type: "simple-line",
                color: {r: 120, g: 217, b: 142, a: 0.7},
                style: "solid",
                width: 4.3
              },
              label: "Suitable"
            }]
          }
          layerOptions.effect = "bloom(2, 0px, 10%) contrast(200%) blur(3.3px)"
          // If this is cleared, we want to make it a different color
          if (featureQuery.indexOf("Clear") !== -1){
            alertRenderer.defaultSymbol.color = {r: 102, g: 102, b: 102, a: 0.7}
            alertRenderer.uniqueValueInfos = []
            alertRenderer.defaultLabel = "Cleared"
            layerOptions.effect = "bloom(2, 0px, 10%) contrast(167%) blur(4px)"
          }
          layerOptions.renderer = alertRenderer
        }

        // Create new feature with the ID output
        let newFeature = new FeatureLayer(layerOptions)
        // .catch(function (error) {
        //   console.log("feature load error", error)
        //   reject({error: error})
        // });

        // Parse the true title out of the URL
        let trueLayerName = getTrueLayerName(featureQuery)
        if (!trueLayerName){
          trueLayerName = newFeature.title
        }
        
        // Add to edit layers
        editLayers[trueLayerName] = newFeature
        newFeature.title = trueLayerName

        // If the title matches, we need to make sure it's visible by default
        // We can include both the staging and prod titles here
        // NOTE: The PARENT group of this has separate visiblity settings
        if ([
          "Paving Plan Test",
          "Paving Plan",
          "Paving Moratorium Test",
          "Paving Moratorium",
          "Project Test",
          "Project",
          "Alerts Test",
          "Alerts",
        ].indexOf(newFeature.title) !== -1){ // More to come
          newFeature.visible = true
        } else { 
          newFeature.visible = false
        }
        // We are adding Complete and Future to default visible
        if (newFeature.title.indexOf("Complete") !== -1 || newFeature.title.indexOf("Future") !== -1 || newFeature.title.indexOf("Under Construction") !== -1){ 
          newFeature.visible = true
        }
        
        // Resolve promise
        resolve(newFeature)
      })
      // Add to our stack
      promiseStack.push(promiseItem)
    }
    return promiseStack
  }

  // Load in all the ArcGIS API modules we'll need
  useEffect(() => {
    loadModules([
      "esri/config",
      "esri/request",
      "esri/Map", 
      "esri/views/MapView", 
      "esri/layers/FeatureLayer",
      "esri/layers/GraphicsLayer", 
      "esri/widgets/Sketch/SketchViewModel",
      "esri/widgets/LayerList", 
      "esri/widgets/Legend",
      "esri/widgets/FeatureForm",
      "esri/widgets/FeatureTable", 
      "esri/views/draw/Draw", 
      "esri/Graphic",
      "esri/geometry/support/webMercatorUtils",
      "esri/geometry/geometryEngineAsync",
      "esri/layers/GroupLayer",
      "esri/Basemap",
      "esri/widgets/BasemapGallery",
      "esri/layers/VectorTileLayer",
      "esri/widgets/Search",
      "esri/widgets/ScaleBar",
      "esri/geometry/Polygon"
    ], {
      css: true
    }).then(([esriConfig, esriRequest, Map, MapView, FeatureLayer, GraphicsLayer, SketchViewModel, LayerList, Legend, FeatureForm, FeatureTable, Draw, Graphic, webMercatorUtils, geometryEngineAsync, GroupLayer, Basemap, BasemapGallery, VectorTileLayer, Search, ScaleBar, Polygon]) => {

      // Separate feature services for local/stage and prod
      // NOTE: The swap happens in validate.js
      const pavingURLs = validateContext.viewLayers.pavingURLs
      const utilityURLs = validateContext.viewLayers.utilityURLs
      const projectURLs = validateContext.viewLayers.projectURLs
      const intersectionURLs = validateContext.viewLayers.intersectionURLs
      // We DO NOT add boundaries to the editLayers object (because we don't want edits on them)
      const boundaryURLs = boundaryLayers.boundaryURLs

      // Get the boundary id from the org
      let boundaryId = cookies.paverReg.id
      // If boundary is blank, we have a problem -- error out
      //console.log("THIS COOKIE", cookies.paverReg)
      if (!boundaryId){
        setError('No service area set! Please inform <a href="/support/feedback">the PaverOps development team</a> of this issue.')
        // Exit early
        return false
      }
      let boundaryService = 'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Service_Area/FeatureServer/0'
      // if (process.env.GATSBY_ENV !== "prod"){
      //   boundaryService = 'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Member_Service_Area_Test/FeatureServer/0'
      // }
      let boundaryReqURL = `${boundaryService}/query?f=json&token=${cookies.paverReg.token}&where=MemberId%20%3D%20%27${boundaryId}%27`
      let extentReqURL = `${boundaryReqURL}&returnExtentOnly=true` // Extra req to get starting extent

      // Get the geometry of the boundary
      fetch(boundaryReqURL)
      .then((resp) => {
        return resp.json()
      }).then((boundary) => {
        // Send the output through so we can render the boundary on the map

        // error: Object { code: 403, message: "You do not have permissions to access this resource or perform this operation.", messageCode: "GWM_0003", … }

        // NOTE: If you, Evan from the future, are seeing this error again, it probably means you need to add your new org's group to the boundary layer: https://maranta.maps.arcgis.com/home/item.html?id=816af833e80249d4b49379ab13f084ef

        // AND also maybe the other non-edit layers
        // https://maranta.maps.arcgis.com/home/item.html?id=ec2778c5f9634bc59d96513ef401d912
        // https://maranta.maps.arcgis.com/home/item.html?id=3405e745e5c34ac0b590bd891d3cebd6
        // https://maranta.maps.arcgis.com/home/item.html?id=c0ecdffd359247a98f86d6c23ec8d94b

        // OR it can mean that this user has not been added to the group!!!
        
        // console.log("boundaryReqURL", boundaryReqURL, boundaryId, boundary)
        if (boundary.error){
          setError('Boundary data from ArcGIS failed upon initial request. This issue may resolve if you press Ctrl + F5. If not, please <a href="/support/feedback">submit a support request</a> and our team will help restore service to this account.')
          // Exit early
          return false
        }

        let boundaryFeature = boundary.features[0]
        if (!boundaryFeature){
          setError('Service area is invalid! Please inform <a href="/support/feedback">the PaverOps development team</a> of this issue.')
          // Exit early
          return false
        }

        let boundaryGeo = boundary.features[0].geometry // Just grab the first one, because we've decided that each org with have ONE matching service area
        boundaryGeo.type = "polygon" // Add a type so this can be used in queries

        // Make a req to get the extent of the service area
        let extentPromise = new Promise((resolve,reject) => {
          fetch(extentReqURL)
          .then((resp) => {
            return resp.json()
          }).then((output) => {
            resolve(output)
          })
        })

        // Iterate through our feature URLs to create our editable layers (confined to the geometry)
        let promiseStack = [extentPromise]
        const pavingLayers = createFeatureLayers(pavingURLs, FeatureLayer)
        const utilityLayers = createFeatureLayers(utilityURLs, FeatureLayer)
        const projectLayers = createFeatureLayers(projectURLs, FeatureLayer)
        const intersectionLayers = createFeatureLayers(intersectionURLs, FeatureLayer)

        // Handle the boundary layers too, result yields immediately
        let boundaryLayersResult = []
        for (let url in boundaryURLs){
          const featureQuery = boundaryURLs[url]
          const newFeature = new FeatureLayer({url: featureQuery})
          // Our favorite hack
          newFeature.title = newFeature.title
          // Make sure these boundaries are hidden by default
          newFeature.visible = false
          boundaryLayersResult.push(newFeature)
        }

        // Stick these all together
        promiseStack = promiseStack.concat(pavingLayers, utilityLayers, projectLayers, intersectionLayers)

        // Wait until all promises are done
        Promise.all(promiseStack).then((values) => {
          // Loop through the values -- if any are null, that means there was a fetch error
          let layerError = false
          for (let i = 0; i < values.length; i++){
            if (values[i].error){
              layerError = true
              console.error(values[i])
            }
          }

          // Count 'em out to know which values are the right ones
          let prevCount = 1
          let pavingLayersResult = values.slice(prevCount, prevCount+pavingLayers.length)
          prevCount += pavingLayersResult.length // Increment
          let utilityLayersResult = values.slice(prevCount, prevCount+utilityLayers.length)
          prevCount += utilityLayersResult.length // Increment
          let projectLayersResult = values.slice(prevCount, prevCount+projectLayers.length)
          prevCount += projectLayersResult.length // Increment
          let intersectionLayersResult = values.slice(prevCount, prevCount+intersectionLayers.length)
          // Weirdly these load in reverse, so pre-reverse them
          // And check them for any error values, we want to strip those before we try to use them in the app
          let stripErrors = (array) => {
            let strippedArray = array.filter((item) => {
              return !item.error
            })
            return strippedArray
          }
          pavingLayersResult = stripErrors(pavingLayersResult).reverse()
          utilityLayersResult = stripErrors(utilityLayersResult).reverse()
          boundaryLayersResult = stripErrors(boundaryLayersResult).reverse()
          projectLayersResult = stripErrors(projectLayersResult).reverse()
          intersectionLayersResult = stripErrors(intersectionLayersResult).reverse()

          // Check for extent
          let extent = values[0].extent
          if (!extent){
            setError('Service area extent not found! Please inform <a href="/support/feedback">the PaverOps development team</a> of this issue.')
            // Exit early
            return false
          }

          let boundaryFeatureLayer = new FeatureLayer({
            url: boundaryService,
            definitionExpression: `MemberId = '${boundaryId}'`
          })
          boundaryLayersResult.push(boundaryFeatureLayer)

          // Create new layer groups
          //console.log("UTILITY ORDER", utilityLayers)
          let groupLayers = [
            new GroupLayer({
              title: "Alerts",
              visible: true,
              visibilityMode: "independent",
              layers: intersectionLayersResult,
            }),
            new GroupLayer({
              title: "Boundaries",
              visible: true,
              visibilityMode: "independent",
              layers: boundaryLayersResult,
            }),
            new GroupLayer({
              title: "Projects",
              visible: true,
              visibilityMode: "independent",
              layers: projectLayersResult,
            }),
            new GroupLayer({
              title: "Utility",
              visible: false,
              visibilityMode: "independent",
              layers: utilityLayersResult,
            }),
            new GroupLayer({
              title: "Paving",
              visible: true,
              visibilityMode: "independent",
              layers: pavingLayersResult,
            })
          ]

          // Enable tooltips across the app
          var Tooltip = {
            tooltip: undefined,
            target: undefined,
            bindEvents: function() {
              Tooltip.tooltip = document.getElementById('tooltip');
              var targets = document.querySelectorAll('[rel=tooltip]' );
              for (var i = 0; i < targets.length; ++i) {
                targets[i].addEventListener('click', function(event){
                  Tooltip.show(event, this)
                  hideOnClickOutside(this, () => {
                    Tooltip.hide()
                  })
                })
                // This was having some weird affects when clicking between tooltips, but we can try again if we want later
                //targets[i].addEventListener('mouseleave', Tooltip.hide)
              }
              Tooltip.tooltip.addEventListener('click', Tooltip.hide)
            },
            show: function(event, target){
              Tooltip.target = target;
              var tip = Tooltip.target.dataset.helptext;
              if( !tip || tip == '' ) {   
                return false;
              }
              Tooltip.tooltip.innerHTML = tip
              Tooltip.tooltip.style.maxWidth = 320 + 'px'
              if (Tooltip.target.dataset.helpsize === "small"){
                Tooltip.tooltip.style.fontSize = "12px"
                Tooltip.tooltip.style.lineHeight = "1.1"
                Tooltip.tooltip.style.textAlign = "left"
              }
              if( window.innerWidth < Tooltip.tooltip.offsetWidth * 1.5 ) {
                Tooltip.tooltip.style.maxWidth = (window.innerWidth / 2)+'px'
              }
              
              Tooltip.tooltip.className = '';
              let leftOffset = (event.clientX - Tooltip.tooltip.offsetWidth/2)
              let topOffset = (event.clientY + 20)
              // We need to set it first so that the if statement below can check if we're over
              Tooltip.tooltip.style.top = topOffset + 'px'

              // Check if we're over, if so, flip it
              if (Tooltip.tooltip.offsetHeight + Tooltip.tooltip.offsetTop > window.innerHeight){
                topOffset = (event.clientY - Tooltip.tooltip.offsetHeight - 45)
              } else {
                Tooltip.tooltip.className += ' top';
              }
               
              // Handle if the tooltip is near the edge of the screen
              if (leftOffset < 0){
                Tooltip.tooltip.className += ' left'
                leftOffset = (event.clientX - 20)
              } else if (leftOffset + Tooltip.tooltip.offsetWidth > window.innerWidth){
                Tooltip.tooltip.className += ' right'
                leftOffset = (event.clientX - Tooltip.tooltip.offsetWidth + 20)
              }
              Tooltip.tooltip.style.left = leftOffset + 'px'
              Tooltip.tooltip.style.top = topOffset + 'px'
              Tooltip.tooltip.className += ' show'
            },
            hide: function(){
              Tooltip.tooltip.className = ''
              Tooltip.tooltip.style = {}
            }
          }

          // Make static objects so our menus don't constantly rebuild
          let staticGroupLayers = JSON.parse(JSON.stringify(groupLayers))
          let staticEditLayers = JSON.parse(JSON.stringify(editLayers))

          // Now set this giant object with the modules so we can use them where we need them
          setModules({esriConfig, esriRequest, Map, MapView, FeatureLayer, GraphicsLayer, SketchViewModel, LayerList, Legend, FeatureForm, FeatureTable, Draw, Graphic, webMercatorUtils, geometryEngineAsync, editLayers, boundaryFeatureLayer, boundaryGeo, extent, groupLayers, Basemap, BasemapGallery, VectorTileLayer, Search, ScaleBar, Tooltip, Polygon, layerError, staticGroupLayers, staticEditLayers})
        })
      })      
    }).catch(err => {
      // handle any errors
      console.error(err)
    })

    // NOTE: It looks like destroying on unmount seems to crash hot reload, so let's try just ... leaving it loaded
    // return () => {
    //   // Loop through and delete the layers
    //   Object.keys(editLayers).forEach((key) => {
    //     if (!!editLayers[key]){
    //       editLayers[key].destroy()
    //       editLayers[key] = null
    //     }
    //   })  
    // }
  },[])

  return (
    <Fragment>
      {error ? (
        <div className="valid-check floating">
          <div className="error-box">
            <img className="error-icon" src="/warning.svg" alt="Warning icon" />
          </div>
          {/* Error and buttons */}
          <div className="button-options">
            <p dangerouslySetInnerHTML={{__html: error}} />
            <button className="primary" onClick={() => {loginFlow(redirectUri)}}>Switch accounts</button>
            <button className="secondary" onClick={() => {navigate("/")}}>Return to home</button>
          </div>
        </div>
      ) : (
        <Fragment>
          {/* Show the loading box while we load the modules */}
          {Object.keys(modules).length === 0 ? ( 
            <div className="valid-check floating">
              <LoadingBox text="Loading site" />
            </div>
          ) : (
            <LoaderContext.Provider value={modules}>
              {children}
            </LoaderContext.Provider>
          )}
        </Fragment>
      )}
      <div id="tooltip" />
    </Fragment>
  )
}

export default MapLoader

